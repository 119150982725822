import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import SvgComponent from "../components/SvgComponent";
import Avatar from "../components/Avatar";
import CopyAndDownloadComponent from "./CopyAndDownloadComponent";
import gfm from 'remark-gfm';


const BotResponse = ({ response}) => {
  const [isImageValid, setIsImageValid] = useState(true); // New state for image validity


  const isOaidalleapiURL = response.startsWith("https://oaidalleapi");

  if (isOaidalleapiURL) {
    const url = response
    return (
      <div className="botMessageMainContainer">
        <div style={{ display: 'flex' }}>
        <div style={{ paddingTop:'16px'}}>
        <Avatar bg="#11a27f" className="openaiSVG" >
          <SvgComponent w={41} h={41} style={{ paddingTop : '10px'}} />
        </Avatar>
      </div>
        <div className="botMessageWrapper">
        {isImageValid ? (
          <div className="oaidalleapi-image">
            <img
              src={url}
              alt="Oaidalleapi Image"
              style={{ maxWidth: '100%', height: 'auto' }}
              onError={(e) => {
                console.error("Error loading image:", e);
                e.target.style.display = 'none';
                setIsImageValid(false); // Set image validity to false when an error occurs
              }}
            />
          </div>
        ) : (
          <div className="image-expired-message">Image has expired</div>
        )}
        </div>
      </div>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ paddingTop:'16px'}}>
        <Avatar bg="#11a27f" className="openaiSVG" >
          <SvgComponent w={41} h={41} style={{ paddingTop : '10px'}} />
        </Avatar>
      </div>
      <div className="text-element" style={{ flex: 1, paddingLeft: '24px', marginTop: '0px' }}>
        <ReactMarkdown remarkPlugins={[gfm]}  
        components={{
          a: ({node, ...props}) => (
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'white' }} 
              {...props}
            />
          ),
          table: ({node, ...props}) => <table className="markdown-table" {...props} />
        }}className="markdown-container">
          {response}
        </ReactMarkdown>
      </div>
      {/* {!canStopResponse  &&  <CopyAndDownloadComponent textToCopy={response} />} */}
      <div className="stop-button"></div>
    </div>
  );
};

export default BotResponse;
