import React from "react";
import BotResponse from "./BotResponse";

const IntroSection = () => {
  return (
    <div id="introsection">
      <h1>
        <div>{process.env.REACT_APP_COMPANY} Bot</div>
      </h1>
      <h3>
        <h2>Your Personal AI Assistant </h2>
      </h3>
      <h3>
        Instant answers to all your questions with the {process.env.REACT_APP_COMPANY} Bot! Dive into work or 
        explore passions without the search hassle.
      </h3>
      <div className="centered-content">
        <p>Here are a few prompts to help you get started:</p>
        <ul>
          <li>Hey! What are you and what can you do for me?</li>
          <li>Could you summarise the below text by highlighting 3 important points in each paragraph?</li>
          <li>You are an analyst and think critically. Given the below scenario, provide your SWOT analysis.</li>
        </ul>
        <p>
          Discover the power of fast-tracking tasks with our new Prompts feature! Click on the prompts bar 
          on the right or use "/" in the input chat area to select a prompt. From summarization to adjusting 
          the tone of voice, let the {process.env.REACT_APP_COMPANY} Bot assist you even more efficiently!
        </p>
        <p>
          Hope you enjoy an easier life with the {process.env.REACT_APP_COMPANY} Bot! <br/> <br/>
          NOTE: The {process.env.REACT_APP_COMPANY} Bot is supported by the OpenAI LLM.  Please ensure that you do not enter any confidential, 
          client, or personal information. The LLM has been trained on data up until early 2023, so any queries about events or concepts after 
          that might cause the Bot to get imaginative with its answers. <b>If you would like more information on how Next 15 processes your 
          personal data, please see our privacy notice <a href="https://docs.google.com/document/d/1hTIg2cwn3RximuPo8FzS4A2GlUlxE4CS/edit?usp=drive_link&ouid=112011862866108876679&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer" > here</a>.</b>

        </p>
      </div>
      <h3>
        <h2>Guidelines </h2>
      </h3>
      <div className="centered-content">
        <h3>Do</h3>
        <ul>
          <li>Understand {process.env.REACT_APP_COMPANY} Bot's purpose and limitations</li>
          <li>Fact check & edit {process.env.REACT_APP_COMPANY} Bot's responses</li>
          <li>Know when to use it</li>
          <li>De-identify information</li>
          <li>Use for work purposes only</li>
        </ul>
        <h3>Don't</h3>
        <ul>
          <li>Rely solely on the {process.env.REACT_APP_COMPANY} Bot</li>
          <li>Share sensitive or confidential information</li>
          <li>Let {process.env.REACT_APP_COMPANY} Bot hinder your critical thinking and creative skills</li>
          <li>Forget about bias and discrimination</li>
        </ul>
      </div>
    </div>
  );
};

export default IntroSection;
