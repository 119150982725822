import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import ModalContent from "./ModalContent";
import PromptInput from "./PromptInput";
import { TbPrompt } from 'react-icons/tb';

const PromptBar = ({inputPrompt, setInputPrompt, onFormSubmit}) => {
  const promptDetails = [
    {
      prompt: "Summarise",
      description: "Summarise information to desired length and form. ",
      // howItWorks: "Summarise this text: {{text}}. Into this form and/or length - {{user_value}}",
      howItWorks:"Could you please provide a concise and comprehensive summary of this text : \"{{text}}\" .\n --- \n The summary should capture the main points and key details of the text while conveying the author's intended meaning accurately.Please ensure that the summary is well-organised and easy to read, with clear headings and subheadings to guide the reader through each section. The length of the summary should be in this form and/or length - {{user_value}}",

      UserInput2: "Instructions to summarise",
      placeholderText1: "Paste or type the text you want to summarise...",
      placeholderText2:"Please provide specific instructions for summarisation such as length, number of sentences, form and so on."

    },
    {
      prompt: "Adjust Tone",
      description: "Adjust the tone of text.",
      howItWorks: "Adjust the tone of this text:  \" {{text}}\". \n --- \n To be {{user_value}} in tone.",
      UserInput2: "Select Tone",
      placeholderText1: "Type or paste your text here....",
      placeholderText2:"Choose the tone of the content (e.g., Formal, Casual, Informative)"

    },
    {
      prompt: "Explain",
      description: "Explains a term concisely at a university graduate level.",
      howItWorks: "Please explain what this means at a university graduate level: \"{{text}}\".\n --- \n Use examples if appropriate. Take into account the context of my job when answering. I heard the term in the context of {{user_value}}. Please answer in around 100 words. Use bullet points for your answer.",
      UserInput2: "Context",
      placeholderText1: "Enter the text/content to be explained....",
      placeholderText2:"Provide context or background information..."

    },
    {
      prompt: "Ideate",
      description: "Generate ideas on a specific topic.",
      howItWorks: "Create some ideas for \"{{user_value}}\" and ways in which I could explore them further.",
      UserInput2: "Your topic/subject",
      placeholderText1:"",
      placeholderText2:"Enter the topic you'd like to brainstorm about..."

    }
  ];
  
  const [selectedPrompt, setSelectedPrompt] = useState(null);

  const handlePromptSelection = (promptDetail) => {
    setSelectedPrompt(promptDetail);
  };

  useEffect(() => {
    // Check if inputPrompt matches any prompt in promptDetails
    const matchedPrompt = promptDetails.find(
      (promptDetail) =>
        promptDetail.prompt
          .toLowerCase()
          .replace(/\//g, '')
          .trim() ===
        inputPrompt.toLowerCase().replace(/\//g, '').trim()
    );

    if (matchedPrompt && !selectedPrompt) {
      setInputPrompt("")
      setSelectedPrompt(matchedPrompt);
    }
  }, [inputPrompt, promptDetails, selectedPrompt]);
  return (
    <div>
      <div className="sideMenuButton">Prompts</div>
      <div className="navPromptWrapper">
        {promptDetails.map((promptDetail) => (
          <div
            key={promptDetail.prompt}
            className="navPrompt"
            onClick={() => handlePromptSelection(promptDetail)}
          >
            <TbPrompt />
            <p>{promptDetail.prompt}</p>
          </div>
        ))}
      </div>
      {selectedPrompt && (
        createPortal(
          <div>
            <div className="backdrop" onClick={() => setSelectedPrompt(null)}></div>
            <PromptInput
              prompt={selectedPrompt.prompt}
              description={selectedPrompt.description}
              howItWorks={selectedPrompt.howItWorks}
              placeholderText1={selectedPrompt.placeholderText1}
              placeholderText2={selectedPrompt.placeholderText2}
              onClose={() => setSelectedPrompt(null)}
              UserInput2={selectedPrompt.UserInput2}
              inputPrompt={inputPrompt}
              setInputPrompt={setInputPrompt}
              onFormSubmit={onFormSubmit}
            />
          </div>,
          document.body
        )
      )}
    </div>
  );
};


export default PromptBar;
