import React, { useContext, useEffect, useRef, useState } from "react";
import Avatar from "../components/Avatar";
import BotResponse from "../components/BotResponse";
import Error from "../components/Error";
import IntroSection from "../components/IntroSection";
import NavContent from "../components/NavContent";
import SvgComponent from "../components/SvgComponent";
import { AuthContext } from "../context/AuthContext";
import PromptBar from "../components/PromptBar";
import InputArea from "../components/InputArea";
import AutocompleteSuggestions from "../components/AutocompleteSuggestions";
import Loading from "../components/Loading";
import CopyToClipboardIcon from "../components/CopyAndDownloadComponent";

const Home = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [chatLog, setChatLog] = useState(() => {
    const savedChatLog = localStorage.getItem(`chatLog_${currentUser.uid}`);
    return savedChatLog ? JSON.parse(savedChatLog) : [[]];
  });
  const [err, setErr] = useState(false);
  const [responseFromAPI, setResponseFromAPI] = useState(false);
  const [currentBotMessage, setCurrentBotMessage] = useState('');
  const textAreaRef = useRef(null);
  const [currentSession, setCurrentSession] = useState(0);
  const chatLogRef = useRef(null);
  const abortController = useRef(new AbortController());
  const [canStopResponse, setCanStopResponse ] = useState(false)
  const company = currentUser.email.split('@')[1];
  const autocompleteSuggestions = ["/adjust tone", "/explain", "/ideate", "/image", "/summarise", "/web-search"];
  const suggestionsContainerRef = useRef(null);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(-1); // -1 means no suggestion is selected

  // const [chatNames, setChatNames] = useState(() => {
  //   const savedChatNames = localStorage.getItem(`chatNames_${currentUser.uid}`);
  //   return savedChatNames ? JSON.parse(savedChatNames) : [];
  // });
  const [chatNames, setChatNames] = useState(() => {
    const savedChatNames = localStorage.getItem(`chatNames_${currentUser.uid}`);
    if (savedChatNames) {
      return JSON.parse(savedChatNames);
    } else {
      const savedChatLog = localStorage.getItem(`chatLog_${currentUser.uid}`);
      if (savedChatLog) {
        const parsedChatLog = JSON.parse(savedChatLog);
        const names = parsedChatLog.map(entry => entry[0].chatPrompt);
        return names;
      } else {
        return [];
      }
    }
  });
  const addItemAtIndex = (newItem, index) => {
    setChatNames((prevChatNames) => {
      const updatedChatNames = [...prevChatNames];
  
      // If index is within the array bounds, update the existing item
      if (index >= 0 && index < updatedChatNames.length) {
        updatedChatNames[index] = newItem;
      } else {
        // If index is out of bounds, add the new item to the end of the array
        updatedChatNames.push(newItem);
      }
  
      return updatedChatNames;
    });
  };

  useEffect(() => {
    // make changes to localstorage when chatNames changes
    localStorage.setItem(`chatNames_${currentUser.uid}`, JSON.stringify(chatNames));

  }, [chatNames]);
  
  // const handleInput = () => {
  //   console.log("hello",textAreaRef.value)
  //   const textarea = textAreaRef.current;
  //   console.log("hello2",textarea.value)
  //   setInputPrompt(textarea.value);

    
  // };
  
  const handleInput = () => {
      if (textAreaRef.current) {
        const textarea = textAreaRef.current;
        setInputPrompt(textarea.value);
    };
  };
  useEffect(() => {
    if (textAreaRef.current) {
      const textarea = textAreaRef.current;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }, [inputPrompt]);


  const handlePromptSubmit =(inputPrompt)=> {
    setInputPrompt(inputPrompt);
  }
  const handleStop = () => {
    if(canStopResponse) {
      setChatLog((prevChatLog) => {
        const updatedCurrentSession = [...prevChatLog[currentSession]];
        updatedCurrentSession[updatedCurrentSession.length - 1].botMessage = currentBotMessage;
        return [
          ...prevChatLog.slice(0, currentSession),
          updatedCurrentSession,
          ...prevChatLog.slice(currentSession + 1),
        ];
      });
      
      setCurrentBotMessage('')
      abortController.current.abort();
      abortController.current = new AbortController();
      setCanStopResponse(false);
    } else {
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!responseFromAPI) {
      if (inputPrompt.trim() !== "") {
        // Set responseFromAPI to true before making the fetch request
        setResponseFromAPI(true);
        const newChatLogEntry = { chatPrompt: inputPrompt, botMessage: 'Loading...' };
        const currentChatSession = chatLog[currentSession];
        if(!chatNames[currentSession]){
          addItemAtIndex(inputPrompt, currentSession);
        }
        const updatedChatSession = [...currentChatSession, newChatLogEntry];
        const updatedChatLog = [
          ...chatLog.slice(0, currentSession),
          updatedChatSession,
          ...chatLog.slice(currentSession + 1),
        ];
        
        setChatLog(updatedChatLog);
        callAPI(newChatLogEntry);

        // hide the keyboard in mobile devices
        if (textAreaRef.current) {
          textAreaRef.current.blur();
        }
      }

      async function callAPI(newChatLogEntry) {
        try {
          setCanStopResponse(true)
          abortController.current = new AbortController();
          // const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/chat`, {
          //   method: "POST",
          //   headers: { "Content-Type": "application/json" },
          //   body: JSON.stringify({ 
          //     message: inputPrompt, 
          //     chatLog : chatLog[currentSession], 
          //     uid : currentUser.sub, 
          //     company : company
          //   }),
          //   signal: abortController.current.signal
          // });
          let response;
           if (inputPrompt.startsWith("/image")) {
            response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/generate-image`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ 
                message: inputPrompt.substring("/image".length), 
                chatLog : chatLog[currentSession], 
                uid : currentUser.uid, 
                company : company
              }),
              signal: abortController.current.signal
            });
          } else if (inputPrompt.startsWith("/web-search")) {
            response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/web-search`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ 
                message: inputPrompt.substring("/web-search".length), 
                chatLog : chatLog[currentSession], 
                uid : currentUser.uid, 
                company : company
              }),
              signal: abortController.current.signal
            });
          } else {
            response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/chat`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ 
                message: inputPrompt, 
                chatLog : chatLog[currentSession], 
                uid : currentUser.uid, 
                company : company
              }),
              signal: abortController.current.signal
            });
          }
          if (!response.ok) {
            setCurrentBotMessage(`Network response was not ok. ${response.statusText}`)
            setCanStopResponse(false)
            throw new Error('Network response was not ok ' + response.statusText);
          }
      
          const reader = response.body.getReader();
          let chunks = '';
          let botMessage = '';
          while (true) {
            const { done, value } = await reader.read();
      
            if (done) {
              break;
            }
      
            chunks += new TextDecoder().decode(value);
            while (true) {
              const eomIndex = chunks.indexOf('}\n');
              if (eomIndex === -1) {
                break;
              }
              const messageJSON = chunks.slice(0, eomIndex + 1);
              chunks = chunks.slice(eomIndex + 2);
              const message = JSON.parse(messageJSON);
              if (message.message === "Loading...") {
                botMessage = message.message;
              } else {
                if (botMessage === "Loading...") {
                  botMessage = message.message;
                } else {
                  botMessage += message.message;
                }
              }
              setCurrentBotMessage(botMessage); 
            }
          }
          setChatLog((prevChatLog) => {
            const updatedCurrentSession = [...prevChatLog[currentSession]];
            updatedCurrentSession[updatedCurrentSession.length - 1].botMessage = botMessage;
            return [
              ...prevChatLog.slice(0, currentSession),
              updatedCurrentSession,
              ...prevChatLog.slice(currentSession + 1),
            ];
            
          });
          
    
          setCurrentBotMessage('');

          setErr(false);
        } catch (err) {
          if (err.name === 'AbortError') {
            setErr(false);
          } else {
            setErr(err);
            console.log(err);
            setCurrentBotMessage(`ERROR: ${err} \n Please report the above error to Tech Support (${process.env.REACT_APP_IT_SUPPORT_1} and ${process.env.REACT_APP_IT_SUPPORT_2}) during working hours. In case of an emergency, please further escalate the issue to the Manager (${process.env.REACT_APP_ESCALATION_SUPPORT_1}).`);
          }
        }
          // Set responseFromAPI back to false after the fetch request is complete
        setResponseFromAPI(false);
        setCanStopResponse(false)
      
      }
    }

    setInputPrompt("");
  };

  const handleRegenerate = async () => {
    const lastInputPrompt = chatLog[currentSession][chatLog[currentSession].length - 1].chatPrompt
    if (!lastInputPrompt || canStopResponse) return; 

    try {
      setCanStopResponse(true)
      setResponseFromAPI(true)
      abortController.current = new AbortController();
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/chat`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
            message: lastInputPrompt, 
            chatLog : chatLog[currentSession].slice(0, chatLog[currentSession].length - 1), 
            uid : currentUser.uid, 
            company : company,
            temperature : Math.round(Math.random() * 100) / 100 
          }),
          signal: abortController.current.signal
      });
        
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
  
      const reader = response.body.getReader();
      let chunks = '';
      let botMessage = '';
      while (true) {
        const { done, value } = await reader.read();
  
        if (done) {
          break;
        }
  
        chunks += new TextDecoder().decode(value);
        while (true) {
          const eomIndex = chunks.indexOf('}\n');
          if (eomIndex === -1) {
            break;
          }
          const messageJSON = chunks.slice(0, eomIndex + 1);
          chunks = chunks.slice(eomIndex + 2);
          const message = JSON.parse(messageJSON);
          botMessage += message.message;
          setCurrentBotMessage(botMessage); 
        }
      }
      setChatLog((prevChatLog) => {
        const updatedCurrentSession = [...prevChatLog[currentSession]];
        updatedCurrentSession[updatedCurrentSession.length - 1].botMessage = botMessage;
        return [
          ...prevChatLog.slice(0, currentSession),
          updatedCurrentSession,
          ...prevChatLog.slice(currentSession + 1),
        ];
      });
      

      setCurrentBotMessage('');

      setErr(false);
    } catch (err) {
      if (err.name === 'AbortError') {
        setErr(false);
      } else {
        setErr(err);
        console.log(err);
        setCurrentBotMessage(`ERROR: ${err} \n Please report the above error to Tech Support (${process.env.REACT_APP_IT_SUPPORT_1} and ${process.env.REACT_APP_IT_SUPPORT_2}) during working hours. In case of an emergency, please further escalate the issue to the Manager (${process.env.REACT_APP_ESCALATION_SUPPORT_1}).`);
      }
    }
    setCanStopResponse(false);
    setResponseFromAPI(false);
  };

  useEffect(() => {
    if (canStopResponse) {
      chatLogRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }

  }, [currentBotMessage, canStopResponse]);

  useEffect(() => {
    localStorage.setItem(`chatLog_${currentUser.uid}`, JSON.stringify(chatLog));
    // console.log("current session home",currentSession)
  }, [chatLog, currentUser.uid]);
  useEffect(() => {
    // console.log('current state changed to', currentSession)
  }, [currentSession]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []); 
  return (
    <>
      <header>
        <div className="menu">
          <button onClick={() => setShowMenu(true)}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#d9d9e3"
              strokeLinecap="round"
            >
              <path d="M21 18H3M21 12H3M21 6H3" />
            </svg>
          </button>
        </div>
        <h1>{process.env.REACT_APP_COMPANY} Bot</h1>
      </header>

      {showMenu && !canStopResponse && (
        <nav>
          <div className="navItems">
            <NavContent
              chatLog={chatLog}
              setChatLog={setChatLog}
              setShowMenu={setShowMenu}
              currentSession={currentSession}
              setCurrentSession={setCurrentSession}
              currentUser={currentUser}
            />
          </div>
          <div className="navCloseIcon">
            <svg
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              xmlSpace="preserve"
              stroke="#fff"
              width={42}
              height={42}
              onClick={() => setShowMenu(false)}
            >
              <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
            </svg>
          </div>
        </nav>
      )}

{/* className={canStopResponse ? 'disabled' : ''}*/ }
      <aside className={`sideMenu ${canStopResponse ? 'disabled' : ''}`}> 
        <NavContent
          chatLog={chatLog}
          setChatLog={setChatLog}
          setShowMenu={setShowMenu}
          currentSession={currentSession}
          setCurrentSession={setCurrentSession}
          currentUser = {currentUser}
          chatNames={chatNames}
          setChatNames={setChatNames}
          addItemAtIndex={addItemAtIndex}
        />
      </aside>

      <section className="chatBox">
        {chatLog[currentSession]?.length > 0 ? (
            <div className="chatLogWrapper">
            {chatLog[currentSession].length > 0 && chatLog[currentSession].map((chat, idx) => (
              <div
                className="chatLog"
                key={idx}
                ref={chatLogRef}
                id={`navPrompt-${(chat.chatPrompt || "").replace(/[^a-zA-Z0-9]/g, "-")}`}

              >
                <div className="chatPromptMainContainer">
                  <div className="chatPromptWrapper">
                    <Avatar bg="#808080" className="userSVG">
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth={1.9}
                        viewBox="0 0 24 24"
                        className="h-6 w-6"
                        height={40}
                        width={40}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                    </Avatar>
                    <div id="chatPrompt">{chat.chatPrompt}</div>
                  </div>

                  <div className="botMessageMainContainer">
                  <div className="botMessageWrapper">
                  <div id="botMessage">
                        {idx === chatLog[currentSession].length - 1 ? (
                          // starts with either /image or /web-search
                          (chatLog[currentSession][idx].chatPrompt.startsWith('/image') || chatLog[currentSession][idx].chatPrompt.startsWith('/web-search') ) 
                          && chatLog[currentSession][idx].botMessage == "Loading..." ? (
                            <Loading></Loading>
                          ) : (
                            <BotResponse
                              response={idx === chatLog[currentSession].length - 1 && currentBotMessage ? currentBotMessage : chatLog[currentSession][idx].botMessage}
                              chatLogRef={chatLogRef}
                              canStopResponse={canStopResponse}
                            />
                          )
                        ) : (
                        <BotResponse
                          response={chat.botMessage}
                          chatLogRef={chatLogRef}
                          canStopResponse={canStopResponse}
                        />
                        
                      )}
                    </div>
                    {idx === chatLog[currentSession].length - 1 && 
                    !chatLog[currentSession][idx].chatPrompt.startsWith("/web-search") &&
                    !chatLog[currentSession][idx].chatPrompt.startsWith("/image") && (
                      <button
                        className="stop-message"
                        onClick={canStopResponse ? handleStop : handleRegenerate}
                      >
                        {canStopResponse ? 'Stop Streaming' : 'Regenerate'}
                      </button>
                    )}
                     {(((idx !== chatLog[currentSession].length - 1 || (idx === chatLog[currentSession].length - 1 && (!canStopResponse || !chat.botMessage=="Loading..."))) && !chatLog[currentSession][idx].chatPrompt.startsWith('/image'))) && (
                            <CopyToClipboardIcon  textToCopy={chat.botMessage}/>
                          )}
                  </div>
                </div>
                </div>
              </div>
            ))}

          </div>
        ) : (
          <div className="chatLogWrapper">
          <IntroSection />
          </div>
        )}
        <div className="mainInputWrapper">

        
        <InputArea
          inputPrompt={inputPrompt}
          setInputPrompt={setInputPrompt}
          handleSubmit={handleSubmit}
          handleInput={handleInput}
          selectedSuggestion={selectedSuggestion}
          setSelectedSuggestion={setSelectedSuggestion}
          autocompleteSuggestions={autocompleteSuggestions}
          suggestionsContainerRef={suggestionsContainerRef}
          textAreaRef={textAreaRef}
        />

        <AutocompleteSuggestions
          inputPrompt={inputPrompt}
          autocompleteSuggestions={autocompleteSuggestions}
          selectedSuggestion={selectedSuggestion}
          setInputPrompt={setInputPrompt}
          suggestionsContainerRef={suggestionsContainerRef}

        />
        </div>
      
        
      </section>
      <aside className={`sideMenu ${canStopResponse ? 'disabled' : ''}`}> 
        <PromptBar
        inputPrompt = {inputPrompt}
        setInputPrompt = {setInputPrompt}
        onFormSubmit={handlePromptSubmit}
        />
      </aside>
    </>
  );
};

export default Home;

