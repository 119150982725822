import React from "react";

const logo_white = require(`../assets/${process.env.REACT_APP_COMPANY}-logo-white.png`);

const CompanyLogo = () => {
  
  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingLeft: '10px'
    }}>
        <img
            src={logo_white}
            alt="company-logo"
            style={{
                // height: "100px",
                width: "200px"
            }}
        />
    </div>

  );
};

export default CompanyLogo;
