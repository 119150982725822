import React, {useEffect} from "react";

const InputArea = ({ inputPrompt, setInputPrompt, handleSubmit, handleInput, selectedSuggestion, setSelectedSuggestion, autocompleteSuggestions, suggestionsContainerRef, textAreaRef}) => {
  
  useEffect(() => {
    //select initial suggestion when displaying suggestions
    setSelectedSuggestion(-1);
  }, []);


  return (
    <form onSubmit={handleSubmit}>
        <div className="inputPromptWrapper">
      <textarea
        ref={textAreaRef}
        name="inputPrompt"
        id=""
        className="inputPromptTextarea"
        autoComplete="off"
        rows="1"
        value={inputPrompt}
        onChange={(e) => {
          setInputPrompt(e.target.value);
          handleInput();
        }}
        onKeyDown={(e) => {
          const filteredSuggestions = autocompleteSuggestions.filter(suggestion =>
            suggestion.toLowerCase().includes(inputPrompt.toLowerCase())
          );
          if (e.key === '/' && selectedSuggestion === -1) {
            // User entered forward slash for the first time
            setSelectedSuggestion(0);
          }
          if (e.key === 'Enter' && e.shiftKey) {
            // Prevent form submission
            e.preventDefault();
            // Insert a newline at the cursor position
            const { selectionStart, selectionEnd } = e.target;
            const before = inputPrompt.substring(0, selectionStart);
            const after = inputPrompt.substring(selectionEnd);
            const newValue = before + "\n" + after;
            // console.log(newValue);
            setInputPrompt(newValue);
          
            // Move the cursor to the next line after state update
            setTimeout(() => {
              textAreaRef.current.selectionStart = selectionStart + 1;
              textAreaRef.current.selectionEnd = selectionStart + 1;
            }, 0);
          }
          if (e.key === 'ArrowUp') {
              // e.preventDefault(); 
              if (selectedSuggestion > 0) {
                setSelectedSuggestion(selectedSuggestion - 1);
                if (suggestionsContainerRef.current) {
                  const suggestionElements = suggestionsContainerRef.current.querySelectorAll('.suggestion-item');
                  if (suggestionElements.length > 0) {
                    const currentSuggestion = suggestionElements[selectedSuggestion];
                    const previousSuggestion = suggestionElements[selectedSuggestion - 1];
                    if (currentSuggestion && previousSuggestion) {
                      currentSuggestion.classList.remove('selected-suggestion');
                      currentSuggestion.scrollIntoView({ behavior: 'auto', block: 'start' });
                      previousSuggestion.classList.add('selected-suggestion');
                    }
                  }
                }
              } else {
                // Wrap to the last suggestion when reaching the top
                setSelectedSuggestion(filteredSuggestions.length - 1);
                if (suggestionsContainerRef.current) {
                  const suggestionElements = suggestionsContainerRef.current.querySelectorAll('.suggestion-item');
                  if (suggestionElements.length > 0) {
                    const firstSuggestion = suggestionElements[0];
                    const lastSuggestion = suggestionElements[suggestionElements.length - 1];
                    if (firstSuggestion && lastSuggestion) {
                      firstSuggestion.classList.remove('selected-suggestion');
                      lastSuggestion.scrollIntoView({ behavior: 'auto', block: 'end' });
                      lastSuggestion.classList.add('selected-suggestion');
                    }
                  }
                }
              }
           } else if (e.key === 'ArrowDown') {
            //e.preventDefault();
             if (selectedSuggestion < filteredSuggestions.length - 1) {
               setSelectedSuggestion(selectedSuggestion + 1);
             } else {
               // Wrap to the first suggestion when reaching the bottom
               setSelectedSuggestion(0);
               if (suggestionsContainerRef.current) {
                const suggestionElements = suggestionsContainerRef.current.querySelectorAll('.suggestion-item');
                if (suggestionElements[selectedSuggestion] && suggestionElements.length > 0) {
                  suggestionElements[selectedSuggestion].classList.remove('selected-suggestion');
                  suggestionElements[selectedSuggestion].scrollIntoView({ behavior: 'auto', block: 'end' });
                  suggestionElements[0].classList.add('selected-suggestion');
                  suggestionElements[0].scrollIntoView({ behavior: 'auto', block: 'start' });
                }
              }
             }
             
           }
           if (suggestionsContainerRef.current) {

            const suggestionElement = suggestionsContainerRef.current.querySelector('.selected-suggestion');
            if (suggestionElement) {
              suggestionElement.scrollIntoView({ behavior: 'auto', block: 'start' });
            }
          }
          
          if (e.key === 'Enter' && selectedSuggestion >= 0 ) {
            const selectedSuggestionText = filteredSuggestions[selectedSuggestion];
            if (selectedSuggestionText) {
              setInputPrompt(selectedSuggestionText + ' ');
            }
            // Clear the selection
            setSelectedSuggestion(-1);
            e.preventDefault();
          } else if (e.key === 'Enter' && !e.shiftKey) {
            // console.log("submitted")
            handleSubmit(e);
          }
         }}
        autoFocus
        style={{
          overflowY: 'auto',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
        }}
      ></textarea>
      <button className= "iconClick" aria-label="form submit" type="submit">
        <svg
          fill="#ADACBF"
          width={22}
          height={22}
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#212023"
          strokeWidth={0}
        >
          <title>{"Send"}</title>
                <path
                  d="m30.669 1.665-.014-.019a.73.73 0 0 0-.16-.21h-.001c-.013-.011-.032-.005-.046-.015-.02-.016-.028-.041-.05-.055a.713.713 0 0 0-.374-.106l-.05.002h.002a.628.628 0 0 0-.095.024l.005-.001a.76.76 0 0 0-.264.067l.005-.002-27.999 16a.753.753 0 0 0 .053 1.331l.005.002 9.564 4.414v6.904a.75.75 0 0 0 1.164.625l-.003.002 6.259-4.106 9.015 4.161c.092.043.2.068.314.068H28a.75.75 0 0 0 .747-.695v-.002l2-27.999c.001-.014-.008-.025-.008-.039l.001-.032a.739.739 0 0 0-.073-.322l.002.004zm-4.174 3.202-14.716 16.82-8.143-3.758zM12.75 28.611v-4.823l4.315 1.992zm14.58.254-8.32-3.841c-.024-.015-.038-.042-.064-.054l-5.722-2.656 15.87-18.139z"
                  stroke="none"
                />
        </svg>
      </button>
      </div>
    </form>
  );
};

export default InputArea;