import React from "react";

const NewChat = ({ setChatLog, setShowMenu , currentSession, setCurrentSession, chatLog}) => {
  return (
    
    <div
  className="sideMenuButton"
  onClick={() => {
    setChatLog(prevChatLog => {
      // Creating a new chat session by appending a new array to the existing chatLog
      if (prevChatLog[prevChatLog.length - 1].length > 0) {
        const newChatLog = [...prevChatLog, []];
      
        // Setting the current session to the index of the new chat session
        setCurrentSession(prevChatLog.length);
        
        return newChatLog;
      } else {
        setCurrentSession(prevChatLog.length - 1);
        return prevChatLog
      }
    });
    setShowMenu(false);
  }}
>
      <span>+</span>
      New chat
    </div>
  );
};

export default NewChat;
