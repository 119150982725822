import { createContext, useEffect, useReducer } from "react";
import reducer from "./AuthReducer";
import { Navigate } from "react-router-dom";


// if (typeof localStorage.getItem("user") === "object" && localStorage.getItem("user") !== null) {
//   initialState = {
//     // currentUser must be object so JSON string must be parsed into JS object
//     currentUser: JSON.parse(localStorage.getItem("user")),
//   };
// } else {
//   initialState = {
//     // currentUser must be object so JSON string must be parsed into JS object
//     currentUser: null,
//   };
// }
const initialState = {
  // currentUser must be object so JSON string must be parsed into JS object
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
};

export const AuthContext = createContext(initialState);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    // need to convert JS object to string to store in localStorage
    localStorage.setItem("user", JSON.stringify(state.currentUser));
  }, [state.currentUser]);
  // useEffect(() => {
  //   // need to convert JS object to string to store in localStorage
  //   // save the state of currentUser to localStorage if only the currentUser is not null or undefined and it should be a json with a filed called sub that has a string value
  //   if (state.currentUser && typeof state.currentUser === "object") {
  //     if (state.currentUser.sub && typeof state.currentUser.sub === "string") {
  //       localStorage.setItem("user", JSON.stringify(state.currentUser));
  //     } else {
  //       localStorage.setItem("user", null);
  //     }
  //   } else {
  //     localStorage.setItem("user", null);
  //   }    
  // }, [state.currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser: state.currentUser, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
