import "./normal.css";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import LoginForm from "./components/login/LoginForm";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';


function App() {
  // const oktaAuth = new OktaAuth(
  //   {
  //     clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  //     issuer: 'https://'+process.env.REACT_APP_OKTA_DOMAIN+'/oauth2/default',
  //     redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
  //     scopes: ['openid', 'profile', 'email'],
  //     pkce: true,
  //     disableHttpsCheck: true,
  //   }
  // );

  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="login" /> ;
  };


  // const { authState, oktaAuth } = useOktaAuth();

  // const RequireAuth = ({ children }) => {
  //   return !authState || !authState.isAuthenticated ? <Navigate to="login" /> : children ;
  // };

  // const history = useHistory(); // example from react-router

  // const navigate = useNavigate();
  
  // const restoreOriginalUri = (_oktaAuth,  originalUri) => {
  //   navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  // };

  // const triggerLogin = async () => {
  //   await oktaAuth.signInWithRedirect();
  // };

  // const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  //   history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  // };

  // const customAuthHandler = async () => {
  //   const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
  //   if (!previousAuthState || !previousAuthState.isAuthenticated) {
  //     // App initialization stage
  //     await triggerLogin();
  //   } else {
  //     // Ask the user to trigger the login process during token autoRenew process
  //     setAuthRequiredModalOpen(true);
  //   }
  // };

  // const customAuthHandler = async () => {
  //   navigate("login");
  // }

  return (
    <div className="App">
    <Routes>
      <Route
        index
        exact
        path="/"
        element={
           <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      {/* <Route exact path="auth/login" element={<Login />} /> */}
      <Route exact path="login" element={<LoginForm />} />
    </Routes>
  </div>
    // let's use the Security component from the Okta library to wrap our app and if authentication is required it should redirect to the login page
    // <Security
    //     oktaAuth={oktaAuth}
    //     onAuthRequired={customAuthHandler}
    //     restoreOriginalUri={restoreOriginalUri}
    //     >
    //   <div className="App">
    //       <Routes>
    //         <Route
    //           index
    //           exact
    //           path="/"
    //           element=
    //           {currentUser ? <Home /> : <Navigate to="login" />}
    //         />
    //         <Route exact path="login" element={<LoginForm />} />
    //         <Route path="login/callback" element={ <LoginCallback />} />
    //       </Routes>
    //   </div>
    // </Security>
  );
}

export default App;
