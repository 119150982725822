import React, { useContext } from "react";
// import { useOktaAuth } from '@okta/okta-react';
import { signOut } from "firebase/auth";
import { auth } from "../firebase.config";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";


const NavLinks = ({ svg, link, text, setChatLog, setCurrentSession ,chatNames, setChatNames}) => {
  const { dispatch, currentUser } = useContext(AuthContext);
  // const { authState, oktaAuth } = useOktaAuth();


  const handleClick = async (text) => {
    if (text === "Clear Conversations") {
      setChatLog([[]]);
      setChatNames([]);
      setCurrentSession(0)
      localStorage.setItem(`chatLog_${currentUser.uid}`, JSON.stringify([[]]));
    }
    else if (text === "Log out") {
      try {
        let logOut = await signOut(auth);
        // let logOut = await oktaAuth.signOut({ postLogoutRedirectUri: process.env.REACT_APP_OKTA_POSTLOGOUT_REDIRECT_URI });
        // console.log("logOut", logOut);
        // localStorage.setItem(`user`, null);
        dispatch({ type: "LOGOUT" });
      } catch (error) {
        console.log("Error during sign out", error);
      }
    }
    else {
      console.log('Random click', text)
      
    }
    
  };

  return (
    <Link
      to={link}
      target={link && "_blank"}
      rel="noreferrer"
      style={{ textDecoration: "none" }}
      onClick={() => handleClick(text)}
    >
      <div className="navPrompt">
        {svg}
        <p>{text}</p>
      </div>
    </Link>
  );
};

export default NavLinks;
