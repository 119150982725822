import React from "react";
import NavLinksContainer from "./NavLinksContainer";
import NavPrompt from "./NavPrompt";
import NewChat from "./NewChat";

const NavContent = ({ chatLog, setChatLog, setShowMenu,currentSession,setCurrentSession, currentUser ,chatNames, setChatNames,addItemAtIndex }) => {
  // console.log('Inside Navcontent',currentSession, chatLog)
  return (
    <>
      <NewChat setChatLog={setChatLog} setShowMenu={setShowMenu} currentSession={currentSession} setCurrentSession={setCurrentSession}  chatLog={chatLog} />
      <div className="navPromptWrapper">
        {chatLog.map((chatSubArray, subArrayIdx) => 
        chatSubArray.length > 0 && chatSubArray[0].chatPrompt && (
         <NavPrompt chatLog={chatLog} 
         chatPrompt={chatNames[subArrayIdx].substring(0, 25)} key={subArrayIdx} chatNames= {chatNames} setChatNames={setChatNames} addItemAtIndex={addItemAtIndex} setShowMenu={setShowMenu} chatId= {subArrayIdx} currentSession={currentSession} setCurrentSession={setCurrentSession}  currentUser = {currentUser} setChatLog={setChatLog}/>
         
        )
        )
        
        }


      </div>
      <NavLinksContainer chatLog={chatLog} setChatLog={setChatLog} setCurrentSession={setCurrentSession} chatNames={chatNames} setChatNames={setChatNames} />
          </>
  );
};

export default NavContent;
