import React, { useState, useEffect, useRef } from 'react';
import { FaExclamation } from "react-icons/fa";
import {RxCross2} from 'react-icons/rx';





const PromptInput = ({ prompt, description, howItWorks, onClose, UserInput2, onFormSubmit,placeholderText1, placeholderText2}) => {
    const [textareaValue1, setTextareaValue1] = useState('');
    const [textareaValue2, setTextareaValue2] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const textarea1Ref = useRef(null);
    const textarea2Ref = useRef(null);

    const handleTextarea1Change = (event) => {
      setTextareaValue1(event.target.value);
      };
    
      const handleTextarea2Change = (event) => {
        setTextareaValue2(event.target.value);
      };
    
      const handleFormSubmit = () => {

        if (prompt !== 'Ideate' && textareaValue1.trim() === '') {
          setErrorMessage('Input fields cannot be empty.');
          setTimeout(() => {
            setErrorMessage('');
          }, 3000);
          return;
        }
        if (textareaValue2.trim() === '') {
          setErrorMessage('Input field  cannot be empty.');
          setTimeout(() => {
            setErrorMessage('');
          }, 3000);
          return;
        }
        
        const howItWorksMessage = howItWorks
        .replace('{{text}}', textareaValue1)
        .replace('{{user_value}}', textareaValue2);
        onFormSubmit(howItWorksMessage)
        onClose()
      };
      useEffect(() => {
        if (textarea1Ref.current) {
          textarea1Ref.current.focus();
        }
        else{
          textarea2Ref.current.focus();
        }
      }, []);
    
  return (
    <div className="modal">
      <p style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', fontWeight: 'bold' }}>
      <span>{prompt}</span>
      <RxCross2 color="white" className="iconClick" style={{paddingRight: '10px', fontSize:"25px"}} onClick={onClose}/>
    </p>
     
      <div style={{ paddingBottom: '10px' }}> <label style={{ fontStyle: 'italic' }}>{description}</label></div>   

      {prompt === 'Ideate' ? null : (
          <div className="form-group">
            <div style={{ paddingBottom: '10px' }}>
              <label>Text</label>
            </div>
            <textarea
              ref={textarea1Ref}
              rows={5}
              className="form-control"
              placeholder={placeholderText1}
              value={textareaValue1}
              onChange={handleTextarea1Change}
            />
          </div>
        )}
      <div className="form-group">
      <div style={{ paddingBottom: '10px',paddingTop:'10px' }}><label>{UserInput2}</label></div>              
      <textarea
          ref={textarea2Ref}
          className="form-control"
          rows={5}
          placeholder={placeholderText2}
          value={textareaValue2}
          onChange={handleTextarea2Change}
        />
      </div>
      <button className = "promptButton" onClick={handleFormSubmit} >Submit</button>
      {errorMessage &&  <p style={{ }}><FaExclamation style={{  color : 'red', paddingRight:'5px',fontSize:'14px'}}/>{errorMessage} 
</p>}
      

    </div>
  );
};

export default PromptInput;
