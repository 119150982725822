import React from 'react';
import { FaRegClipboard } from "react-icons/fa";

class CopyToClipboardIcon extends React.Component {
  constructor(props) {
    super(props);
    this.copyText = this.copyText.bind(this);
  }

  copyText() {
    navigator.clipboard.writeText(this.props.textToCopy)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  }

  render() {
    return (
      <div  style={{ cursor: 'pointer' }} className="clipboard" onClick={this.copyText}>
        <FaRegClipboard />

      </div>
    );
  }
}

export default CopyToClipboardIcon;
