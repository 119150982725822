import React from "react";

const AutocompleteSuggestions = ({ inputPrompt, autocompleteSuggestions, selectedSuggestion, setInputPrompt, suggestionsContainerRef }) => {
  const lowercasedInput = inputPrompt.toLowerCase();

  return (
    <div ref={suggestionsContainerRef} className="autocomplete-suggestions">
      {inputPrompt &&
        autocompleteSuggestions
        .filter((suggestion) => suggestion.startsWith(lowercasedInput))
          .map((suggestion, index) => (
            <div
              className={`suggestion-item ${index === selectedSuggestion ? "selected-suggestion" : ""}`}
              key={index}
              onClick={() => setInputPrompt(suggestion)}
            >
              {suggestion}
            </div>
          ))}
    </div>
  );
};

export default AutocompleteSuggestions;
