import React , {useState, useEffect ,  useLayoutEffect, useRef }  from "react";
import {AiOutlineDelete,AiOutlineCheck,AiOutlineEdit} from 'react-icons/ai';
import {RxCross2} from 'react-icons/rx';

const NavPrompt =({ chatLog, setChatLog, chatPrompt, setShowMenu , chatId ,subArrayIdx, currentSession, setCurrentSession, currentUser,chatNames, setChatNames,addItemAtIndex  }) => {
  const chatPromptCharacters = chatPrompt.split("");
  const [deleteClicked, setDeleteClicked] = useState(false)
  const navPromptHref = `navPrompt-${chatPrompt.replace(/[^a-zA-Z0-9]/g, "-")}`;
  const newChatLog= []
  
  const [editClicked, setEditClicked] = useState(false);
  const [editedPrompt, setEditedPrompt] = useState(chatPrompt);
  const inputRef = useRef(null);
  // useEffect(() => {
  //   // Focus the input field when editClicked becomes true
  //   if (editClicked && inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [editClicked]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      editConfirm();
    }
  };

  function deleteAtNthIndex(arr1, arr2, index ) {
    if (index >= 0 && index < arr1.length && index < arr2.length) {
      const newArr1 = [...arr1.slice(0, index), ...arr1.slice(index + 1)];
      const newArr2 = [...arr2.slice(0, index), ...arr2.slice(index + 1)];
      return [newArr1, newArr2];
    } else {
      return [arr1, arr2];
    }
  }
  function deleteClick(){
    setDeleteClicked(true)
  }
  const editClick = () => {
    setEditClicked(true);
    setDeleteClicked(false); 
    // Focus the input field when editClicked becomes true 
    // (Timeout with 0ms allows any pending rendering updates to complete)
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };
  
  function deleteCancelled(){
    setDeleteClicked(false)
    setEditClicked(false);

  }
  function editConfirm() {
    addItemAtIndex(editedPrompt,currentSession)
    setEditClicked(false);
  }
  function editCancelled(){
    setEditClicked(false);
  }

  function deleteConfirm(){
    const [newChatLog, newChatNames] =deleteAtNthIndex(chatLog, chatNames, currentSession)
    if(newChatLog.length==0){
      setChatLog([[]]);
      setChatNames([])
    }
    else{
      newChatLog.push([]);
      setChatNames(newChatNames)
      setChatLog(newChatLog);
    }
    setDeleteClicked(false)
  }
  useEffect(() => {
    setDeleteClicked(false);
    setEditClicked(false);
  }, [currentSession, chatId]);


  return (
    <div className={`navPrompt${currentSession === chatId ? ' selected' : ''}`}   onClick={() => {
      // switching chats - currentSession , chatId
      if (!deleteClicked) {
        //delete empty array if user clicks on new chat and moves to an existing chat.
        chatLog = chatLog.filter(subArray => subArray.length > 0);
        setChatLog(chatLog);
      }
      
    setCurrentSession(chatId);
    }} >
      <a href={`#${navPromptHref}`} onClick={() => setShowMenu(false)}>
        <svg
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          fill="#ECECF1"
          stroke="#ECECF1"
          width={16}
          height={16}
        >
          <path
            fill="#ECECF1"
            fillRule="evenodd"
            d="M15 3.25A2.25 2.25 0 0 0 12.75 1h-9.5A2.25 2.25 0 0 0 1 3.25v11a.75.75 0 0 0 1.26.55l2.801-2.6a.75.75 0 0 1 .51-.2h7.179A2.25 2.25 0 0 0 15 9.75v-6.5zm-2.25-.75a.75.75 0 0 1 .75.75v6.5a.75.75 0 0 1-.75.75H5.572a2.25 2.25 0 0 0-1.531.6L2.5 12.53V3.25a.75.75 0 0 1 .75-.75h9.5z"
            clipRule="evenodd"
          />
        </svg>
        {editClicked ? (
          <input
          id="editInput"
          key="editInput"  
          type="text"
          value={editedPrompt}
          onChange={(e) => setEditedPrompt(e.target.value)}
          ref={inputRef}
          onClick={(e) => 
            {e.stopPropagation() 
            e.preventDefault()}} // Add this line to stop propagation
          autoComplete="off" 
          onKeyDown={handleKeyPress} // Add this line to handle Enter key press
          />
        ) : (
          <p>
            {chatPromptCharacters.map((char, idx) => (
              <span key={idx} style={{ animationDelay: `${idx * 0.1}s` }}>
                {char}
              </span>
            ))}
          </p>
        )}
        <div className="deleteBox">
      {(currentSession === chatId) && deleteClicked && (
        <>
          <AiOutlineCheck color="white" className="iconClick" onClick={deleteConfirm} />
          <RxCross2 color="white" className="iconClick" onClick={deleteCancelled} />
        </>
      )}

      {(currentSession === chatId) && editClicked && (
        <>
          <AiOutlineCheck color="white" className="iconClick" onClick={editConfirm} />
          <RxCross2 color="white" className="iconClick" onClick={editCancelled } />
        </>
      )}

      {(currentSession === chatId) && !deleteClicked && !editClicked && (
        <>
          <AiOutlineEdit className="iconContainer iconClick" color="white" onClick={editClick} />
          <AiOutlineDelete className="iconContainer iconClick" color="white" onClick={deleteClick} />
        </>
      )}
    </div>
        
        
      </a>
    </div>
  );
};

export default NavPrompt;
